import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

import Seamless from "../components/Seamless"
import Button from "@bit/azheng.joshua-tree.button"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

const AcademicAdvantageApply = () => {
  let language = "en"

  const goBackURL = "/academicadvantage/"
  const seamlessId = "f3130d861b649e2f5d7d884ddc1d7c7e"
  const webinarURL =
    "https://attendee.gotowebinar.com/register/6991867422937277709"
  const rsvpThrough = "May 15, 2021"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          lang={language}
          title="Scholarship Application - Fresno Oral Maxillofacial Surgery & Dental Implant Center"
          description="Fresno Oral Maxillofacial Surgery & Dental Implant Center is proud to announce its 2021 Virtual Academic Advantage,"
        />

        <Button buttonText="BACK" goBack href={goBackURL} />

        <div className="edu-scholarship">
          <div className="edu-hero">
            <img
              className="hero-bg"
              src="https://res.cloudinary.com/nuvolum/image/upload/v1615826776/Programs/edu-banner.jpg"
              alt="Academic Advantage banner"
            />
          </div>

          <h1>Scholarship Application</h1>

          <div className="seamless-wrapper">
            <iframe
              class="seamlessdocsEmbededIframe"
              src={`https://secureform.seamlessdocs.com/f/${seamlessId}?embedded=true`}
              width="100%"
              height="700px"
              frameborder="0"
              allowtransparency="true"
              sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
            ></iframe>
          </div>

          <div className="form-section single">
            <div className="outer-border">
              <div className="bordered-box">
                <h3 className="rsvp">RSVP</h3>
                <h4 className="content-heading">now through {rsvpThrough}</h4>

                <a
                  href={webinarURL}
                  target="_blank"
                  className="registration-btn"
                >
                  Register
                </a>
              </div>
            </div>

            <p
              className="edu-disclaimer"
              style={{ textAlign: "left", margin: "20px 0 40px 0" }}
            >
              *Eligible students must RSVP and apply for scholarship prior to
              event date. Scholarship recipient(s) must be present during the
              event.
            </p>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default AcademicAdvantageApply
